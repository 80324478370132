import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <div className={"mt-16"}>
      <h1 className={"text-6xl"}>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
